import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/ReadingClub/2024/ReadingDay/1.jpg';
import p1 from 'assests/Photos/Clubs/ReadingClub/2024/ReadingDay/1.jpg';

import p2 from 'assests/Photos/Clubs/ReadingClub/2024/ReadingDay/2.jpg';
import p3 from 'assests/Photos/Clubs/ReadingClub/2024/ReadingDay/3.jpg';
import p4 from 'assests/Photos/Clubs/ReadingClub/2024/ReadingDay/4.jpg';
// import p5 from 'assests/Photos/SchoolProgram/2023/RepublicDay/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2023/RepublicDay/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/2023/RepublicDay/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/2023/RepublicDay/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/2023/RepublicDay/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/2023/RepublicDay/10.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../ReadingClub/components/Sidebar2024';

const ReadingDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
      
        
      
       
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                READING DAY	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Date: 19 June 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Department of Library, National Public School, Yeshwanthpur celebrated Reading Day Programme on Wednesday, 19 June 2024 to commemorate the death anniversary of Late P. N Panicker, who is considered as ‘The father of the Library Movement’ in India. Two students of Class 11C highlighted the importance of reading followed by a pledge in the assembly. A plethora of activities like ‘Book Mark Making’, ‘Poster Designing’, ‘Character Sketch’ and ‘Guess the Title’ have been organised for the students.
                                    <br></br>
                                    The students of Class 4 participated in the event D.E.A.R. (Drop Everything And Read) forming the word “BOOK” where the students sat together and read. On this occasion, Principal Ms.Sheeja Manoj  emphasised on the significance of reading, advised the students to manage their time in reading and stated that friends will not be available with us all the time, but books will stay on with us in all circumstances.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “A Reader lives thousands of lives before he dies. A man who never reads lives only one” – George R.R Martin ”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ReadingDay2024;